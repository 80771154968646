import axios from '../instance'
import { CourierQuoteResponse } from './type'

export const getCourierQuoteList = async (): Promise<
	CourierQuoteResponse[]
> => {
	try {
		const response = await axios.get('/courier-quote')
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
